import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Layout from "../components/layout/Layout";
import routes from "../routes/routes";
import token from "../features/token/TokenContainer";
import blank from "../features/blank/BlankContainer";
import admin from "../features/admin/AdminContainer";

const features: { [key: string]: React.FC } = {
  admin,
  blank,
  redirect: () => <Redirect to="/token" />,
  token,
};

export default function Routes() {
  return (
    <Router>
      <Switch>
        {routes.map((route) => {
          const Component = features?.[route.feature] || features.blank;
          return (
            <Route {...(route.href === '/' ? { exact: true } : {})} path={route.href} key={route.label}>
              <Layout>{<Component />}</Layout>
            </Route>
          );
        })}
      </Switch>
    </Router>
  );
}

import React from 'react'

function BlankContainer() {
  return (
    <div>
      Page not found 404
    </div>
  )
}

export default BlankContainer

import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useValidateAccessKeyMutation } from "../../../api/admin";
import Input from "../../../components/input/Input";
import { setSessionCookie } from "../../../utils/cookies";

function SelectToken() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors }
  } = useForm();

  const [validateAccessKey, { isLoading }] = useValidateAccessKeyMutation();

  const onSubmit = (data: { token?: string }) => {
    if (data.token) {
      validateAccessKey({ access_key: data.token })
        .unwrap()
        .then((result) => {
          toast.success(t("admin.validated"));
          setSessionCookie("access_key", data.token as string);
          history.push("/admin/access");
        })
        .catch((error) => {
          const errors = {
            upload_center_invalid_access_key: 'admin.invalid-access-token',
          }
          const errorTranslations = errors?.[error?.data?.error?.error_code as 'upload_center_invalid_access_key'];
          if (errorTranslations) {
            setError(
              "token",
              {
                types: { manual: t(errorTranslations) as string }
              },
              { shouldFocus: true }
            );
            toast.error(t(errorTranslations));
          } else {
            toast.error(t("error"));
          }
        });
    }
  };

  const { token } = watch();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>{t("admin.title-access")}</h1>
      <p>{t("admin.description-token")}</p>

      <Box sx={{ mt: "80px", maxWidth: 400, textAlign: "right" }}>
        <Input label={t("admin.enter-key")} id="token" placeholder={t("admin.token")} {...register("token")} errors={errors} />

        <LoadingButton variant="contained" sx={{ mt: "40px" }} type="submit" disabled={!token} loading={isLoading} size="large">
          {t("next")}
        </LoadingButton>
      </Box>
    </form>
  );
}

export default SelectToken;

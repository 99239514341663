import { Autocomplete, Button, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateTokenMutation } from '../../../api/admin';
import Input from '../../../components/input/Input';
import { getCookie } from '../../../utils/cookies';
import { LoadingButton } from '@mui/lab';
import { DateTime } from 'luxon';
import { useGetRemoteEnvironmentsQuery } from '../../../api/remote_servers';
import { RemoteEnvironment } from '../../../api/remote_servers.interface';
import { ScanType } from '../../../api/admin.interface';

function CreateToken() {
  const { t } = useTranslation();
  const history = useHistory();
  const accessKey = getCookie('access_key');
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customer: '',
      emails: '',
      date: DateTime.now().plus({ months: 1 }).endOf('day').toISODate().slice(0, 10), // YYYY-MM-DD format
      scanType: 'None',
      remoteIp: '',
      scanDtName: '',
      scanDtVersion: '',
    },
  });

  const { customer, scanType, remoteIp, scanDtName, scanDtVersion } = watch();
  // create dynamic allow_submit cont (customer should be filled, also remoteIp, dtName, dtVersion should be filled if selectedScanType === 'RemoteInstance')
  const allowSubmit = customer !== '' && (scanType !== 'RemoteInstance' || (remoteIp !== '' && scanDtName !== '' && scanDtVersion !== ''));

  const [createToken, { isLoading }] = useCreateTokenMutation();

  const { data } = useGetRemoteEnvironmentsQuery({ access_key: accessKey });
  const [servicesOptions, setServicesOptions] = useState<RemoteEnvironment[]>([]);

  const onSubmit = (tokenData: {
    customer: string;
    date: string;
    emails: string;
    remoteIp: string;
    scanDtName: string;
    scanDtVersion: string;
    scanType: ScanType;
  }) => {
    createToken({
      access_key: accessKey,
      customer: tokenData.customer,
      email_addresses: tokenData.emails,
      expiry_date: DateTime.fromISO(tokenData.date).toISO(),
      remote_ip: tokenData.remoteIp,
      scan_dt_name: tokenData.scanDtName,
      scan_dt_version: tokenData.scanDtVersion,
      scan_type: tokenData.scanType,
    })
      .unwrap()
      .then(() => {
        reset();
        toast.success(t('admin.token-success'));
        handleCancel();
      })
      .catch((error) => {
        toast.error(error?.data?.error_message || 'Error');
      });
  };

  useEffect(() => {
    if (scanType === 'RemoteInstance') {
      setServicesOptions(data as RemoteEnvironment[]);
    } else {
      setServicesOptions([]);
      setValue('remoteIp', '');
      setValue('scanDtName', '');
      setValue('scanDtVersion', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, scanType, accessKey]);

  const handleRemoteIpSelection = (event: React.ChangeEvent<{}>, selectedValue: string | null) => {
    if (selectedValue) {
      setValue('remoteIp', selectedValue);
    } else {
      setValue('remoteIp', '');
    }
  };

  const handleCancel = () => history.push('/admin/access');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ width: 650 }} spacing={2}>
        <Grid item xs={8}>
          <h1>{t('admin.create')}</h1>
        </Grid>
        <Grid item xs={8}>
          <p>{t('admin.description-create')}</p>
        </Grid>
        <Grid item xs={8}>
          <Input autoFocus label={t('admin.customer')} id="customer" placeholder={t('admin.add-customer')} {...register('customer')} errors={errors} />
        </Grid>
        <Grid item xs={8}>
          <Input
            label={t('admin.date')}
            id="date"
            {...register('date')}
            errors={errors}
            type="date"
            defaultValue={DateTime.now().plus({ months: 1 }).endOf('day').toISODate().slice(0, 10)}
          />
        </Grid>
        <Grid item xs={8}>
          <Input
            label={t('admin.emails')}
            id="emails"
            placeholder={'example@email.com, test@email.com'}
            {...register('emails')}
            errors={errors}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl>
            <Controller
              name="scanType"
              control={control}
              defaultValue="None"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel control={<Radio color="default" />} value="None" label="No auto-scan" />
                  <FormControlLabel control={<Radio color="default" />} value="RemoteInstance" label="Remote server" />
                  <FormControlLabel control={<Radio color="default" />} value="Molly" label="Molly" />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
        {scanType === 'RemoteInstance' && (
          <React.Fragment>
            <Grid item xs={8}>
              <InputLabel id="autocomplete-label">{t('admin.remote_instance')}</InputLabel>
              <Autocomplete
                id="autocomplete"
                {...register('remoteIp')}
                options={servicesOptions.map((option) => option.remote_ip)}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} />}
                onChange={handleRemoteIpSelection}
              />
            </Grid>
            <Grid item xs={8}>
              <Input label={t('admin.dt_name')} id="scanDtName" placeholder={t('admin.dt_name')} {...register('scanDtName')} errors={errors} />
            </Grid>
            <Grid item xs={8}>
              <Input label={t('admin.dt_version')} id="scanDtVersion" placeholder={t('admin.dt_version')} {...register('scanDtVersion')} errors={errors} />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={8} sx={{ mt: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={5}>
              <Button onClick={handleCancel} disabled={isLoading} variant="contained" size="large" fullWidth>
                {t('admin.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton type="submit" disabled={!allowSubmit} loading={isLoading} variant="contained" size="large" fullWidth>
                {t('admin.create')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default CreateToken;

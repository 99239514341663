import { ToastOptions } from "react-toastify";

const variables = {
  BASE_URL: process.env.REACT_APP_API_URL || '/api', // 'http://localhost:30010/api'
}

export const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
} as ToastOptions;

export const languages = {
  // Do not disable English. Never.
  en: {
    title: 'English',
    flag: 'gb',
    current: true,
  },
  fr: {
    title: 'French',
    flag: 'fr',
  },
  de: {
    title: 'German',
    flag: 'de',
  },
  'zh-CN': {
    title: 'Chinese',
    flag: 'cn',
  },
  jp: {
    title: 'Japanese',
    flag: 'jp',
    locale: 'ja',
  },
  kr: {
    title: 'Korean',
    flag: 'kr',
    locale: 'ko',
  },
};

export default variables;
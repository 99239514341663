import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';
import { useVerifiedEmailMutation } from '../../../api/admin';
import { Token } from '../../../api/admin.interface';
import { useTranslation } from 'react-i18next';
import { getCookie } from '../../../utils/cookies';

export default function EmailDialog({ open, setOpen, token, verified }: { open: boolean; setOpen: VoidFunction; token?: Token; verified: boolean }) {
  const { t } = useTranslation();
  const [verifiedEmail, { isLoading }] = useVerifiedEmailMutation();
  const accessKey = getCookie('access_key');
  const handleClose = (): void => {
    if (!isLoading) {
      setOpen();
    }
  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (token) {
      verifiedEmail({ access_key: accessKey, token_id: token.id, verified })
        .unwrap()
        .then(() => {
          toast.success(t('admin.email-sent'));
          setOpen();
        })
        .catch((error) => {
          toast.error(error?.data?.error_message || 'Error');
          setOpen();
        });
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{t('admin.send-title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {verified ? t('admin.send-verified-text') : t('admin.send-not-verified-text')} <b>{token?.customer}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: '16px', mr: '16px' }}>
          <Button onClick={handleClose} disabled={isLoading}>
            {t('admin.cancel')}
          </Button>
          <LoadingButton variant="contained" color="error" sx={{ color: 'white' }} type="submit" disabled={isLoading} loading={isLoading}>
            {t('admin.send')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

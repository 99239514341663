import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../features/admin/dialogs/DeleteDialog";
import { visuallyHidden } from "@mui/utils";
import { Columns, Data, EnhancedTableProps, Order } from "./interface";
import { Skeleton } from "@mui/material";
import { randomIntFromInterval } from "../../utils/helpers";
import "./Table.scss";
import { getCookie, setCookie } from "../../utils/cookies";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, selectedCheckbox = false, columns } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {selectedCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell?.width || "auto", padding: "8px", borderBottom: 0 }}
            className="table-head"
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  selected: Data[];
  setSelected: React.Dispatch<React.SetStateAction<Data[]>>
  numSelected: number;
  title?: string;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const { selected, setSelected, numSelected, title = "" } = props;

  const handleDeleteSelectedRows = () => {
    setDeleteDialogOpen(true);
  };

  const closeDialog = () => {
    setDeleteDialogOpen(false);
    setSelected([]);
  };

  return (
    <div>
      <Toolbar className={numSelected > 0 ? 'toolbar toolbar-selected' : 'toolbar'} >
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
        )}
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={handleDeleteSelectedRows}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <DeleteDialog open={deleteDialogOpen} setOpen={closeDialog} tokens={selected}></DeleteDialog>
    </div>
  );
};

const emptyData = [...Array(5)].map(() => ({}));

export default function EnhancedTable({
  columns,
  rows,
  selectedCheckbox = false,
  loading,
  title = "",
  pagination = false

}: {
  columns: Columns[];
  rows: Data[] | [];
  selectedCheckbox?: boolean;
  loading: boolean;
  title?: string;
  pagination?: boolean;
}) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("calories");
  const [selected, setSelected] = React.useState<Data[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(getCookie("rows_per_page"), 10) || 10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
      return;
    } else {
      setSelected([]);
    }
  };

  const handleSelectRow = (token: Data) => {
    if (selected.includes(token)) {
      setSelected(selected.filter((rowToken) => rowToken !== token));
    } else {
      setSelected([...selected, token]);
    }
  };

  const isSelected = (token: Data) => selected.includes(token);

  const handleClick = (event: React.MouseEvent<unknown>, row: Data) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected: Data[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCookie("rows_per_page", event.target.value, 365);
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }} className="table-container">
      <EnhancedTableToolbar selected={selected} setSelected={setSelected} numSelected={selected.length} title={title}/>
      <TableContainer>
        <Table sx={{ pl: "4px", pr: "4px", minWidth: 750, borderSpacing: "0 16px", borderCollapse: "separate" }} aria-labelledby="tableTitle" size="medium">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            selectedCheckbox={selectedCheckbox}
            columns={columns}
            title={title}
          />
          <TableBody>
            {!loading &&
              rows
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row?.id} ${index.toString()}`}
                      selected={isItemSelected}
                      className="table-row"
                    >
                      {selectedCheckbox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={() => handleSelectRow(row)}
                            inputProps={{
                              "aria-labelledby": labelId
                            }}
                          />
                        </TableCell>
                      )}
                      {columns.map((column) => (
                        <React.Fragment key={column.id}>
                          {column.id === "name" ? (
                            <TableCell id={labelId} scope="row" padding="none">
                              {row.name}
                            </TableCell>
                          ) : column?.Cell ? (
                            <TableCell>{column?.Cell(row)}</TableCell>
                          ) : (
                            <TableCell sx={{ padding: "8px" }}>{row?.[column.id]}</TableCell>
                          )}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  );
                })}

            {loading &&
              emptyData.map((row, index) => (
                <TableRow key={index.toString()} className="table-row">
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <Skeleton width={randomIntFromInterval(50, 200)} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";
import { useRemoveTokensMutation } from "../../../api/admin";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../utils/cookies";
import { Data } from "../../../components/table/interface";

export default function DeleteDialog({ open, setOpen, tokens }: { open: boolean; setOpen: VoidFunction; tokens?: Data[] }) {
  const { t } = useTranslation();
  const [removeTokens, { isLoading }] = useRemoveTokensMutation();
  const accessKey = getCookie("access_key");
  const handleClose = (): void => {
    if (!isLoading) {
      setOpen();
    }
  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (tokens) {
      removeTokens({ access_key: accessKey, token_ids: tokens.map((token) => token.id).join(',') })
        .unwrap()
        .then(() => {
          toast.success(t("admin.removed"));
          setOpen();
        })
        .catch((error) => {
          toast.error(error?.data?.error_message || 'Error');
          setOpen();
        });
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{tokens ? tokens?.map((token) => token.customer).length > 1 ? t("admin.removed-tokens-title") : t("admin.removed-token-title") : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {tokens ? tokens?.map((token) => token.customer).length > 1 ? t("admin.removed-tokens-text") : t("admin.removed-token-text") : ''}<b>{tokens?.map((token) => token.customer).join(', ')}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: "16px", mr: "16px" }}>
          <Button onClick={handleClose} disabled={isLoading}>
            {t("admin.cancel")}
          </Button>
          <LoadingButton variant="contained" color="error" sx={{ color: "white" }} type="submit" disabled={isLoading} loading={isLoading}>
            {t("admin.delete")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

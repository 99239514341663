import { Box, Button, Grid, Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";
import "./UploadProgress.scss";
import LinearProgress from "../linearProgress/LinearProgress";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/error.svg";

function UploadProgress({ uploadStats, cancelUpload }: { uploadStats: any, cancelUpload: any }): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="upload-box" style={{ width: "unset" }}>
      <div className="upload-title">
        {!uploadStats.error && uploadStats.progress !== 100 && (
          <Tooltip title={<div>{uploadStats.progress}</div>} placement="top" arrow enterDelay={100}>
            <div>
              {t("upload-files.in-progress")} <span className="upload-percentage">({uploadStats.progress?.toFixed(0) || 0}%)</span>
            </div>
          </Tooltip>
        )}
        {!uploadStats.error && uploadStats.progress === 100 && (
          <>
            {t("upload-files.complete")} <span className="upload-percentage">({uploadStats.progress.toFixed(0)}%)</span>
          </>
        )}
        {uploadStats.error && (
          <>
            <div className="upload-failure">
              {t("upload-files.failure")}
              <Box sx={{ ml: "16px" }}>
                <ErrorIcon />
              </Box>
            </div>
            <div className="upload-failure-subtitle">{t("upload-files.failure-description")}</div>
          </>
        )}
      </div>
      {!uploadStats.error && (
        <Grid container wrap="nowrap" justifyContent="flex-start" alignItems="center" sx={{ mt: "8px" }}>
          <Grid item xs={11}>
            <Tooltip title={<div>{uploadStats.progress}</div>} placement="top" arrow enterDelay={100}>
              <div>
                <LinearProgress className="tiny" variant="determinate" value={uploadStats.progress || 0} />
              </div>
            </Tooltip>
          </Grid>
          <Grid item className="xs-ml-8">
            <Button
              className={clsx({ "hide-visibility": uploadStats.error || uploadStats.progress === 100 })}
              onClick={cancelUpload}
              color="primary"
              size="small"
              sx={{
                ml: "8px",
                color: "var(--color-gold)"
              }}
            >
              {t("upload-files.cancel")}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default UploadProgress;

import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import AdminAccess from "./components/AdminAccess";
import EnterToken from "./components/EnterToken";
import CreateToken from "./components/CreateToken";
import { getCookie } from "../../utils/cookies";
import RemoteServers from "./components/RemoteServers";

function TokenContainer() {
  const { path } = useRouteMatch();
  const history = useHistory()

  useEffect(() => {
    if (!getCookie('access_key')) {
      history.replace('/admin')
    }
  }, [history])

  return (
    <Switch>
      <Route exact path={path}>
        <EnterToken />
      </Route>
      <Route path={`/admin/access`}>
        <AdminAccess />
      </Route>
      <Route path={`/admin/create`}>
        <CreateToken />
      </Route>
      <Route path={`/admin/remote-servers`}>
        <RemoteServers />
      </Route>
    </Switch>
  );
}

export default TokenContainer;

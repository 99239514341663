import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, RadioGroup } from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../app/store";
import Input from "../../../components/input/Input";
import Radio from "../../../components/radio/Radio";
import SelectForm from "../../../components/select/SelectForm";
import useVerifyTokenExists from "../hooks/useVerifyTokenExists";
import { setTokenInformation } from "../tokenSlice";

function Information() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const informationState = useSelector((state: RootState) => state.token.information);

  useVerifyTokenExists();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      controller: informationState?.controller || "",
      architecture: informationState?.architecture || "",
      other: informationState?.other
    }
  });

  const onSubmit = async (data: { controller: string; architecture?: string; other?: string }) => {
    dispatch(
      setTokenInformation({
        controller: data.controller,
        architecture: data.controller === "true" ? data.architecture : undefined,
        other: controller === "true" && data.architecture === "Other" ? data.other : undefined,
      })
    );
    history.push(`/token/upload`);
  };

  const architectureOptions = [
    { value: "", label: " " },
    { value: "ARM-based", label: "ARM-based" },
    { value: "ARM Thumb-based", label: "ARM Thumb-based" },
    { value: "Intel x86", label: "Intel x86" },
    { value: "Intel x64", label: "Intel x64" },
    { value: "MIPS", label: "MIPS" },
    { value: "PowerPC", label: "PowerPC" },
    { value: "PowerPC VLE", label: "PowerPC VLE" },
    { value: "TriCore", label: "TriCore" },
    { value: "Renesas RH850", label: "Renesas RH850" },
    { value: "I'm not sure", label: t("information.not-sure") },
    { value: "Other", label: t("information.other") }
  ];

  const { architecture, controller } = watch();
  const width = 400;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: width }}>
        <p>{t("information.description-1")}</p>
        <p>{t("information.description-2")}</p>
      </Box>

      <Box sx={{ mt: "80px", maxWidth: width, minWidth: width }}>
        <InputLabel>{t("information.controller")}</InputLabel>
        <FormControl component="fieldset" sx={{ mt: "12px" }}>
          <Controller
            control={control}
            name="controller"
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <RadioGroup row className="xs-mt-16" value={value} onChange={(e) => {
                  onChange(e);
                }}>
                  <FormControlLabel sx={{ mr: "40px" }} value={"true"} control={<Radio />} label={t("information.yes")} />
                  <FormControlLabel sx={{ mr: "40px" }} value={"false"} control={<Radio />} label={t("information.no")} />
                  <FormControlLabel sx={{ mr: "40px" }} value={""} control={<Radio />} label={t("information.not-sure")} />
                </RadioGroup>
              );
            }}
          />
        </FormControl>

        {controller === "true" && (
          <>
            <Box sx={{ mt: "24px" }}>
              <SelectForm label={t("information.architecture")} id="architecture" control={control} errors={errors}>
                {architectureOptions.map((architecture) => (
                  <MenuItem key={architecture.value} value={architecture.value}>
                    {architecture.label}
                  </MenuItem>
                ))}
              </SelectForm>
            </Box>
            {architecture === "Other" && (
              <Box>
                <Input id="other" placeholder={t("information.specify-architecture")} {...register("other")} errors={errors} />
              </Box>
            )}
          </>
        )}

        <LoadingButton className="full-width" variant="contained" sx={{ mt: "80px" }} type="submit" size="large">
          {t("continue")}
        </LoadingButton>
      </Box>
    </form>
  );
}

export default Information;

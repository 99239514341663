import { Select, SelectProps } from '@mui/material';
import React, { forwardRef } from 'react';

interface SelectHOCProps extends SelectProps {
  children: React.ReactNode;
  disablePortal?: boolean;
}
const SelectHOC: React.FunctionComponent<SelectHOCProps> = forwardRef(({ children, disablePortal = false, ...rest }: SelectHOCProps, ref) => {
  return (
    <Select
      MenuProps={{
        disablePortal,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </Select>
  );
});

export default SelectHOC;

import { MenuItem, SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';
// import { Settings } from 'luxon';
import React from 'react';
import i18n from '../../app/i18n';
import cn from '../../assets/images/flags/cn.svg';
import de from '../../assets/images/flags/de.svg';
import fr from '../../assets/images/flags/fr.svg';
import gb from '../../assets/images/flags/gb.svg';
import jp from '../../assets/images/flags/jp.svg';
import kr from '../../assets/images/flags/kr.svg';
import us from '../../assets/images/flags/us.svg';
import Select from '../select/Select';
import { LangSwitchProps } from './inerface';
import './LanguageSelect.scss';
import { languages } from '../../config';
import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from '../../utils/cookies';

const flags = {
  cn,
  de,
  fr,
  gb,
  jp,
  kr,
  us,
};

const LangSwitch: React.FunctionComponent<LangSwitchProps> = ({ inverse, onChange, value, className, disabled }: LangSwitchProps) => {
  const { t } = useTranslation();

  const currentLanguage = getCookie('lang') || 'en'

  const handleChangeLanguage = (event: SelectChangeEvent<unknown>) => {
    const alias = event?.target?.value as string;
    if (typeof onChange === 'function') {
      onChange(alias);
    } else {
      i18n.changeLanguage(alias);
      setCookie('lang', alias, 365);
      // Settings.defaultLocale = alias;
      // dispatch(setCurrentLanguage({ alias }));
    }
  };

  return (
    <Select
      displayEmpty
      onChange={handleChangeLanguage}
      inputProps={{ className: clsx(className || '', { inverse }) }}
      {...(inverse ? { MenuProps: { PaperProps: { className: 'inverse' }, MenuListProps: { className: 'inverse' } } } : {})}
      label={t('language-select.language')}
      className="lang-switch wide"
      value={value || currentLanguage}
      disabled={disabled}
    >
      {Object.keys(languages).map((lang: string) => {
        const language = t(`language-select.${languages[lang as 'en'].title}`);
        return (
          <MenuItem key={lang} value={lang}>
            <img src={flags[languages[lang as 'en'].flag as 'gb']} alt={language} className="language-select-icon" />
            {language}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default LangSwitch;

import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Token } from '../../../api/admin.interface';
import { useTranslation } from 'react-i18next';

export default function MoreInformationDialog({ open, setOpen, token }: { open: boolean; setOpen: VoidFunction; token?: Token }) {
  const { t } = useTranslation();
  const handleClose = (): void => {
    setOpen();
  };
  const isMicrocontroller = () => {
    if (token?.is_microcontroller === true) {
      return t('information.yes');
    }
    if (token?.is_microcontroller === false) {
      return t('information.no');
    }
    if (token?.is_microcontroller === null) {
      return '—';
    }
  };

  const scanTypeMap = {
    None: t('No'),
    RemoteInstance: t('Remote server'),
    Molly: t('Molly'),
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form>
        <DialogTitle>{t('admin.more-info')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ minWidth: 400 }}>
            {t('admin.dt-name')}: <b>{token?.dt_name ? token?.dt_name : '—'}</b> <br />
            {t('admin.version')}: <b>{token?.dt_version_name ? token?.dt_version_name : '—'}</b> <br />
            {t('admin.component-type')}: <b>{token?.component_type ? token?.component_type : '—'}</b> <br />
            {t('admin.architecture')}: <b>{token?.architecture ? token?.architecture : '—'}</b> <br />
            {t('admin.microcontroller')}: <b>{isMicrocontroller()}</b> <br />
            {t('Auto-scan')}: <b>{token?.scan_type ? scanTypeMap[token.scan_type] : '—'}</b> <br />
            {token?.scan_type === 'RemoteInstance' && (
              <React.Fragment>
                {t('Auto-scan remote server')}: <b>{token?.remote_ip ? token?.remote_ip : '—'}</b> <br />
                {t('Auto-scan DT name')}: <b>{token?.dt_scan_name ? token?.dt_scan_name : '—'}</b> <br />
                {t('Auto-scan DT version')}: <b>{token?.dt_scan_version ? token?.dt_scan_version : '—'}</b> <br />
              </React.Fragment>
            )}
          </DialogContentText>
        </DialogContent>
      </form>
    </Dialog>
  );
}

import { createTheme } from "@mui/material/styles";

export const commonTheme = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "&.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--color-text)",
          borderWidth: 1
        }
      },
      notchedOutline: {
        top: 0,
        "& legend": {
          display: "none"
        }
      }
    }
  }
};

const theme = createTheme({
  typography: {
    fontSize: 16
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
          textTransform: "none",
          fontSize: 16,
          color: "var(--color-text)",
          "&.button-large": {
            padding: "12px 48px"
          }
        },
        containedPrimary: {
          "&:focus, &:active, &:hover": {
            boxShadow: "none"
          },
          color: "white",
          background: "var(--background-button)",
          textTransform: "none",
          fontSize: 16,
          boxShadow: "none",
          "&.Mui-disabled": {
            color: "var(--color-text-dark)",
            background: "rgba(0, 0, 0, 0.3)",
            opacity: 0.5
          }
        },
        textSizeLarge: {
          fontSize: 16
        },
        containedSizeLarge: {
          padding: "6px 32px"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // override input label margin-top
          "label + &": {
            marginTop: 28
          },
          // override Select label margin-top
          "label + &.MuiInput-formControl.MuiInputBase-root": {
            marginTop: 28
          },
          // override Select underline
          "&.MuiInput-underline:after, &.MuiInput-underline:before, &.MuiInput-underline:hover:not(.Mui-disabled):before": {
            border: 0
          },
          "&.Mui-error input, &.Mui-error textarea, &.Mui-error .MuiSelect-select": {
            border: "1px solid",
            borderColor: "var(--color-error)"
          }
          // '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child, &.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input':
          //   {
          //     padding: "12px 16px"
          //   },
          // '&.MuiAutocomplete-inputRoot[class*="Mui-error"]': {
          //   borderColor: "var(--color-error)",
          //   "& .MuiAutocomplete-input": {
          //     border: "none !important"
          //   }
          // },
          // '&.MuiAutocomplete-inputRoot[class*="disabled"]': {
          //   opacity: 0.5,
          //   cursor: "default"
          // }
        },
        input: {
          position: "relative",
          boxShadow: "var(--shadow-input)",
          borderRadius: "4px",
          backgroundColor: "white",
          color: "var(--color-text-dark)",
          border: "1px solid",
          borderColor: "var(--color-input-border)",
          fontSize: 16,
          height: 22,
          fontWeight: "normal",
          letterSpacing: "normal",
          padding: "12px 16px",
          "&:focus": {
            borderColor: "var(--color-text)"
          },
          "&.Mui-disabled": {
            opacity: 0.5
          },
          "&.no-shadow": {
            boxShadow: "none"
          },
          "&.MuiInputBase-inputAdornedEnd": {
            paddingRight: 40
          }
        },
        inputMultiline: {
          padding: "16px"
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          position: "absolute",
          top: 24,
          right: 10,
          zIndex: 1
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--color-text-dark)',
          fontSize: 16,
          fontWeight: "normal",
          "&.Mui-focused": {
            color: 'var(--color-text-dark)',
          },
          "&.Mui-error": {
            color: "var(--color-error)"
          }
        },
        // not allow to scale down input label by default
        formControl: {
          transform: "translate(0, 0) scale(1)"
        },
        shrink: {
          transform: "translate(0, 0) scale(1)"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginLeft: 0,
          fontSize: "16px",
          "&.Mui-error": {
            color: "var(--color-error)"
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: 16,
          textAlign: "left"
        },
        select: {
          borderRadius: 3,
          paddingLeft: 16,
          paddingRight: 32,
          paddingTop: 10,
          paddingBottom: 10,
          position: "relative",
          border: "1px solid",
          borderColor: "var(--color-input-border)",
          boxShadow: "var(--shadow-input)",
          "&.MuiSelect-select": {
            paddingRight: "32px"
          },
          "&:focus": {
            borderRadius: "3px",
            backgroundColor: "white"
          }
        },
        filled: {
          color: "var(--color-text)",
          "&:focus": {
            backgroundColor: "transparent"
          },
          border: "none",
          boxShadow: "none",
          "&.MuiSelect-filled": {
            padding: "0 64px 0 0"
          },
          "&:before": {
            fontSize: 16,
            color: "var(--color-text-dark)"
          },
          "&.Mui-disabled": {
            opacity: 0.5
          }
        },
        icon: {
          marginRight: "4px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          "&.MuiButtonBase-root": {
            minHeight: 36,
          }
        }
      }
    },
    // related to select input filled (empty without background)
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent"
          },
          "&.Mui-focused": {
            backgroundColor: "transparent"
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 16,
          color: 'var(--color-text-dark)'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 24,
          borderRadius: 4,
          "&.small": {
            height: 10
          },
          "&.tiny": {
            height: 6
          }
        },
        bar: {
          borderRadius: 4
        },
        colorPrimary: {
          backgroundColor: "var(--color-gray-progress)"
        },
        barColorPrimary: {
          backgroundColor: "var(--color-gold)"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'var(--color-gold)',
          textDecoration: 'none',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: 'var(--color-gold)',
            '& + .MuiSwitch-track': {
              backgroundColor: 'var(--color-gold)',
            },
          },
        },
      },
    },
    ...commonTheme
  }
});

export default theme;

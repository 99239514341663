import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useValidateTokenMutation } from "../../../api/upload";
import { securityPolicy } from "../../../app/appSlice";
import Input from "../../../components/input/Input";
import useQuery from "../../../hooks/useQuery";
import { setTokenInformation } from "../tokenSlice";

function SelectToken() {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      token: query.get("token_id") || ""
    }
  });

  const [validateToken, { isLoading }] = useValidateTokenMutation();

  const onSubmit = (data: { token?: string }) => {
    if (data?.token) {
      validateToken({ token_id: data?.token })
        .unwrap()
        .then((result) => {
          dispatch(securityPolicy(true));
          dispatch(setTokenInformation({ token_id: data?.token }));
          history.push(`/token/information`);
        })
        .catch((error) => {
          const errors = {
            upload_center_token_not_exists: "enter-token.not-exist",
            upload_center_invalid_token: "enter-token.used-expired"
          };
          const errorTranslations = errors?.[error?.data?.error?.error_code as "upload_center_token_not_exists"];
          if (errorTranslations) {
            setError(
              "token",
              {
                types: { manual: t(errorTranslations) as string }
              },
              { shouldFocus: true }
            );
          } else {
            toast.error(t("error"));
          }
        });
    }
  };

  const { token } = watch();
  const width = 400;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: width }}>
        <p>{t("enter-token.description-1")}</p>
        <p>{t("enter-token.description-2")}</p>
      </Box>
      <Box sx={{ mt: "80px", maxWidth: width, minWidth: width, textAlign: "right" }}>
        <Input label={t("enter-token.upload")} id="token" placeholder={t("enter-token.enter")} {...register("token")} errors={errors} />
        <LoadingButton variant="contained" sx={{ mt: "40px" }} type="submit" disabled={!token} loading={isLoading} size="large" className="full-width">
          {t("next")}
        </LoadingButton>
      </Box>
    </form>
  );
}

export default SelectToken;

import { LinearProgress, LinearProgressProps } from "@mui/material";
import React, { ReactElement } from "react";

interface ColoredLinearProgressProps extends LinearProgressProps {
  value?: number;
}

function ColoredLinearProgress({ value = 0, ...rest }: ColoredLinearProgressProps): ReactElement {
  return <LinearProgress value={value} {...rest} />;
}

export default ColoredLinearProgress;

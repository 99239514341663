import { Box, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { ReactNode } from "react";
import Logo from "../logo/Logo";
import "./Layout.scss";
import theme, { commonTheme } from "../../config/muiTheme";
import LanguageSelect from "../languageSelect/LanguageSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from '../../app/store';

function Layout({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const securityPolicy = useSelector((state: RootState) => state.app.securityPolicy)
  return (
    <>
      <Grid container wrap="nowrap" className="layout-container full-height">
        {/* Left panel */}
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark"
            },
            components: {
              MuiListItemIcon: {
                styleOverrides: {
                  root: {
                    minWidth: 40
                  }
                }
              },
              ...commonTheme
            }
          })}
        >
          <Grid item className="layout-left full-height">
            <Grid container wrap="nowrap" direction="column" justifyContent="flex-start" className="full-height">
              <Grid item>
                <Logo />
              </Grid>
              <Grid item alignSelf="center">
                <LanguageSelect />
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justifyContent="space-between"
          className="full-width full-height"
          sx={{ backgroundColor: "var(--color-light-background)", maxHeight: "100%", overflow: "hidden" }}
        >
          {/* Content */}
          <Grid item className="full-width full-height" sx={{ backgroundColor: "var(--color-light-background)", maxHeight: "100%", overflow: "hidden" }}>
            <ThemeProvider theme={theme}>
              <Box sx={{ py: "40px", px: "72px", m: 0, overflow: "auto", position: "relative", height: "calc(100% - 80px)" }}>{children}</Box>
            </ThemeProvider>
          </Grid>
          {/* Footer */}
          <Grid item className="full-width full-height" sx={{ backgroundColor: "var(--color-light-background)", maxHeight: "80px", overflow: "hidden" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="full-width full-height"
              wrap="nowrap"
              sx={{ backgroundColor: "var(--color-light-background)", maxHeight: "80px", overflow: "hidden", fontSize: 14 }}
            >
              <Grid container wrap="nowrap" sx={{ ml: '0px' }} spacing={8}>
                {securityPolicy && <Grid item><a target="_blank" href="/policy/Cybellum_Platform_Cloud_Security_Guide-en.pdf" rel="noreferrer">{t('security-policy')}</a></Grid> }
                <Grid item><a target="_blank" href="https://cybellum.com/privacy-policy/" rel="noreferrer">{t('privacy-policy')}</a></Grid>
                <Grid item><a target="_blank" href="https://cybellum.com/terms-conditions/" rel="noreferrer">{t('terms-of-use')}</a></Grid>
                <Grid item><a target="_blank" href="https://cybellum.com/contact-us/" rel="noreferrer">{t('contact-us')}</a></Grid>
              </Grid>
              <Grid item sx={{ minWidth: '350px', ml: '80px', color: 'var(--color-text)' }}>{t('copyright')}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Layout;

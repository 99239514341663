import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import EnterToken from "./components/EnterToken";
import Information from "./components/Information";
import Success from "./components/Success";
import UploadFiles from "./components/UploadFiles";

function TokenContainer() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ mb: "24px", width: 400 }}>
        <h1>{t("title")}</h1>
      </Box>
      <Switch>
        <Route exact path={path}>
          <EnterToken />
        </Route>
        <Route path={`/token/information`}>
          <Information />
        </Route>
        <Route path={`/token/upload`}>
          <UploadFiles />
        </Route>
        <Route path={`/token/success`}>
          <Success />
        </Route>
      </Switch>
    </>
  );
}

export default TokenContainer;

import { Radio as RadioMUI, RadioProps } from "@mui/material";
import React, { ForwardedRef, forwardRef } from "react";
import { styled } from "@mui/material/styles";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24,
  height: 24,
  boxShadow: "var(--box-shadow-input)",
  backgroundColor: "#fff",
  border: '1px solid var(--color-input-border)',
  ".Mui-focusVisible &": {
    outline: "1px solid var(--color-input-border)",
    outlineOffset: 2
  },
  "input:hover ~ &": {
    backgroundColor: 'rgba(0,0,0,.05)'
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)"
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#fff",
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(var(--color-text-dark),var(--color-text-dark) 38%,transparent 40%)',
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: 'rgba(0,0,0,.05)'
  }
});

const Radio = forwardRef((props: RadioProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
  return (
    <RadioMUI
      sx={{
        "&:hover": {
          bgcolor: "transparent"
        }
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputRef={ref}
      {...props}
    />
  );
});

export default Radio;

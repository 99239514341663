import { Box, Grid } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { ReactComponent as SuccessIcon } from "../../../assets/images/icons/success.svg";

function Success() {
  const { t } = useTranslation();
  const successState = useSelector((state: RootState) => state.token.success);
  const width = 400;
  return (
    <>
      <Grid container direction="column" sx={{ width }}>
        <Grid item sx={{ ml: "150px", mt: "75px" }}>
          <SuccessIcon />
        </Grid>
        <Grid item sx={{ mt: "40px", textAlign: "center" }}>
          <Box sx={{ color: "var(--color-text-dark)", fontWeight: 600 }}>{t("upload-files.success-1")}</Box>
          {successState?.email_addresses && successState?.email_addresses?.length > 0 && (
            <Box sx={{ mt: "12px" }}>
              <p>
                <Trans i18nKey="upload-files.success-2" t={t}>
                  A notification email will be sent to <Box sx={{ color: "var(--color-gold)"}}>{{ email: successState?.email_addresses?.join(", ") }}</Box> once the evaluation is completed.
                </Trans>
              </p>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Success;

import update from 'immutability-helper';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Resumable from 'resumablejs';
import { getAppSessionId } from '../../../app/appSlice';
import { formatBytes } from '../../../utils/helpers';
import { useTranslation } from "react-i18next";
import variables from "../../../config";
import { resumableUploadsConfigSelector } from "../tokenSlice";

export interface ResumableFile extends Resumable.ResumableFile {
  hidden?: boolean;
  _prevProgress?: number;
}

export interface IFile {
  file: ResumableFile;
  id: string;
  isError: boolean;
  isCompleted: boolean;
  isUploading: boolean;
  isRetry: boolean;
  progress: number;
}

export interface IResumable extends Resumable {
  getFileId: (file: ResumableFile) => string;
  unAssignBrowse?: (element: Element | Element[]) => void;
}

type Size = { size: number; sizeUnits: string; sizeLabel: string; binary?: boolean; number?: boolean };

export type ResumableStats = {
  size: number;
  total: Size;
  loaded: Size;
  error?: { message: string };
  completed: boolean;
  progress: number;
  uploading: boolean;
  retry: boolean;
};

const defaultConfig = {
  target: `${variables.BASE_URL}/upload/resumable_uploads/upload_resumable_file`,
  chunkSize: 50 * 1024 * 1024,
  testChunks: false,
  simultaneousUploads: 3,
  maxFiles: 1,
  maxChunkRetries: Math.min(Number.MAX_SAFE_INTEGER, 10 * 1000 * 60 * 60 * 24 * 3), // undefined for unlimited not working
  // chunkRetryInterval: 3000, // cancel while offline not working correct with chunkRetryInterval (not timeout clear on cancel)
};

const initialUploadStats = {
  size: 0,
  retry: false,
  completed: false,
  progress: 0,
  uploading: false,
  total: {},
  loaded: {},
} as ResumableStats;

export default function useResumable({ slug, browseRef, skip }: { slug: string; browseRef: RefObject<HTMLInputElement>; skip?: boolean }): {
  files: any,
  uploadStats: ResumableStats;
  removeFile: (fileId: string) => void;
  initialResumable: IResumable | null;
  uploadFiles: () => Promise<string[] | void>;
  cancelUpload: VoidFunction;
} {
  const { t: resumableT } = useTranslation();
  const sessionId = useSelector(getAppSessionId);
  const [files, setFiles] = useState<{ [key: string]: IFile }>({});
  const [uploadStats, setUploadStats] = useState<ResumableStats>(initialUploadStats);
  const resumableUploadsConfig = useSelector(resumableUploadsConfigSelector);
  const progressRef = useRef(0);
  const timeoutRef = useRef(0);
  const config = useMemo(
    () =>
      resumableUploadsConfig && !isEmpty(resumableUploadsConfig)
        ? {
            ...defaultConfig,
            chunkSize: resumableUploadsConfig?.chunk_size,
            simultaneousUploads: resumableUploadsConfig?.concurrent_uploads,
            // maxChunkRetries: resumableUploadsConfig?.session_expiry_minutes * 60 * 10,
          }
        : defaultConfig,
    [resumableUploadsConfig],
  );

  const initialResumable = useMemo(() => {
    if (skip) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const resumable: IResumable = new Resumable({
      ...config,
      generateUniqueIdentifier: () => `${slug}_${nanoid()}`,
      headers: {},
      query: {
        sessionId,
      },
    });

    resumable.getFileId = (file) => file.uniqueIdentifier;

    const convertFile = (file: ResumableFile): IFile => ({
      file,
      id: resumable.getFileId(file),
      isError: false,
      isCompleted: false,
      isUploading: false,
      isRetry: false,
      progress: 0,
    });

    resumable.on('filesAdded', (addedFiles) => {
      setUploadStats(initialUploadStats);
      setFiles((old) => {
        const mapped = addedFiles.map((file) => ({
          [resumable.getFileId(file)]: convertFile(file),
        }));
        return Object.assign({}, ...mapped);
      });
    });

    resumable.on('fileProgress', (file: ResumableFile) => {
      setFiles((old) => {
        const fileKey = resumable.getFileId(file);
        const prevProgress = file._prevProgress || 0; // eslint-disable-line
        const progress = file.progress(true);

        return fileKey && old[fileKey]
          ? // && progress !== prevProgress
            update(old, {
              [fileKey]: {
                progress: { $set: progress },
                ...(progress !== prevProgress && { isRetry: { $set: false } }),
                isUploading: { $set: true },
              },
            })
          : old;
      });
    });

    resumable.on('fileError', (file) => {
      setFiles((old) => {
        const fileKey = resumable.getFileId(file);
        return fileKey && old[fileKey] ? update(old, { [fileKey]: { isError: { $set: true }, isUploading: { $set: false } } }) : old;
      });
    });

    resumable.on('fileSuccess', (file) => {
      setFiles((old) => {
        const fileKey = resumable.getFileId(file);
        return fileKey && old[fileKey] ? update(old, { [fileKey]: { isCompleted: { $set: true }, isUploading: { $set: false } } }) : old;
      });
    });

    resumable.on('fileRetry', (file) => {
      setFiles((old) => {
        const fileKey = resumable.getFileId(file);
        return fileKey && old[fileKey] ? update(old, { [fileKey]: { isRetry: { $set: true } } }) : old;
      });
    });

    return resumable;
  }, [slug, skip, sessionId, config]);

  useEffect(() => {
    if (browseRef.current && initialResumable && !skip) {
      initialResumable.assignBrowse(browseRef.current, false);
    }
  }, [initialResumable, browseRef, skip]);

  const removeFile = (slug: string) => {
    const file = Object.entries(files).find(([fileSlug]) => fileSlug === slug)?.[1];
    if (!file) {
      return;
    }

    file.file.cancel();
    file.file.resumableObj.removeFile(file.file);
    setFiles((old) => omit(old, file.id));
  };

  const cancelUpload = useCallback(() => {
    initialResumable?.cancel();
    setFiles({});
    setUploadStats(initialUploadStats);
  }, [initialResumable]);

  useEffect(() => {
    if (skip || !initialResumable) {
      return;
    }
    const size = initialResumable.getSize() as unknown as number;
    const progress = initialResumable.progress();

    setUploadStats((stats) => {
      const error = Object.keys(files).some((key) => files[key].isError);

      // Fix for FF requests stuck when offline
      clearInterval(timeoutRef.current);
      if (initialResumable.isUploading()) {
        timeoutRef.current = setTimeout(() => {
          initialResumable.pause();
          initialResumable.upload();
        }, 15000) as unknown as number;
      }

      if (!error) {
        progressRef.current = progress;
      }

      return {
        size,
        loaded: formatBytes(size * (error ? progressRef.current : progress)),
        total: formatBytes(size),
        error: !stats.error && error ? { message: resumableT('Upload failed') } : stats.error,
        retry: Object.keys(files).some((key) => files[key].isRetry),
        completed: size > 0 && Object.keys(files).every((key) => files[key].isCompleted),
        progress: Math.round((error ? progressRef.current : progress) * 100 * 10) / 10,
        uploading: initialResumable.isUploading(),
      };
    });
  }, [files, initialResumable, setUploadStats, skip, resumableT]);

  const uploadFiles = useCallback(async () => {
    if (!skip && initialResumable) {
      initialResumable.upload();
    }

    return new Promise<string[] | void>((resolve, reject) => {
      if (skip || !initialResumable) {
        reject(new Error(resumableT('Resumable Uploads is not enabled')));
        return;
      }
      if (isEmpty(files)) {
        resolve();
        return;
      }
      const resolver = () => {
        resolve(Object.keys(files));
      };

      const rejecter = (error: Error) => {
        reject(error);
      };

      initialResumable.on('beforeCancel', rejecter);
      initialResumable.on('error', rejecter);
      initialResumable.on('complete', resolver);
    });
  }, [initialResumable, skip, resumableT, files]);

  return {
    files,
    uploadStats,
    removeFile,
    initialResumable,
    uploadFiles,
    cancelUpload,
  } as const;
}

const omit = <T>(obj: T, key: string) => Object.fromEntries(Object.entries(obj).filter(([k]) => k !== key));

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { setToFormDataUnserialized } from '../utils/helpers';
import {RemoteEnvironment} from "./remote_servers.interface";

export const remoteSeversApi = createApi({
  reducerPath: 'remoteSeversApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.BASE_URL}/admin`,
  }),
  tagTypes: ['RemoteEnvironments'],
  endpoints: (build) => ({
    getRemoteEnvironments: build.query<RemoteEnvironment[], { access_key: string }>({
      query: (data) => `/remote_servers/get_environments?access_key=${data.access_key}`,
      transformResponse: (response:  { environments: RemoteEnvironment[] } ) => response?.environments,
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'RemoteEnvironments' as const, id })), 'RemoteEnvironments'] : ['RemoteEnvironments']),
    }),
    addRemoteEnvironment: build.mutation<RemoteEnvironment, { access_key: string; remote_ip: string; user: string; password: string }>({
      query: (data) => ({
        url: `/remote_servers/add_environment`,
        method: 'POST',
        body: setToFormDataUnserialized(data),
      }),
      transformResponse: (response: { environment: RemoteEnvironment }) => response.environment,
      invalidatesTags: () => ['RemoteEnvironments'],
    }),
    editRemoteEnvironmentDetails: build.mutation<RemoteEnvironment[], { access_key: string; environment: RemoteEnvironment }>({
      query: (data) => ({
        url: '/remote_servers/update_environment_details',
        method: 'POST',
        body: setToFormDataUnserialized({access_key: data.access_key, remote_ip: data.environment.remote_ip, user: data.environment.user, password: data.environment.password}),
      }),
      transformResponse: (response: { environments: RemoteEnvironment[] }) => response.environments,
      invalidatesTags: () => ['RemoteEnvironments'],
    }),
    removeRemoteEnvironment: build.mutation<{}, { remote_ip: string; access_key: string }>({
      query: (data) => ({
        url: `/remote_servers/remove_environment`,
        method: 'POST',
        body: setToFormDataUnserialized(data),
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'RemoteEnvironments', ids: arg?.remote_ip }],
    }),
  }),
});

export const { useGetRemoteEnvironmentsQuery, useAddRemoteEnvironmentMutation, useEditRemoteEnvironmentDetailsMutation, useRemoveRemoteEnvironmentMutation} = remoteSeversApi;

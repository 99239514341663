import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, Box, ListItemIcon, IconButton } from "@mui/material";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

export default function CustomizedMenus({
  itemId,
  actions,
  handleAction
}: {
  itemId: string;
  actions: {
    option: string;
    icon: EmotionJSX.Element;
    label: string;
  }[];
  handleAction: ({ id, option }: { id: string; option: string }) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { dataset } = event.target as HTMLElement;
    handleAction({ id: itemId, option: dataset.option || "" });
    handleClose();
  };

  return (
    <>
      <IconButton aria-expanded={open ? "true" : undefined} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <MenuItem key={itemId} onClick={handleItemClick} disableRipple sx={{ color: "var(--color-text)", fontSize: 16 }}>
            <ListItemIcon sx={{ fontSize: 24, color: "var(--color-gray)", mt: "-3px" }}>{action.icon}</ListItemIcon>
            <Box data-id={itemId} data-option={action.option} sx={{ pr: "8px" }}>
              {action.label}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export const setCookie = (name: string, value: string, days = 7, path = '/'): void => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
};
export const setSessionCookie = (name: string, value: string, path = '/'): void => {
  document.cookie = `${name}=${encodeURIComponent(value)}; path=${path}`;
};

export const getCookie = (name: string): string => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export const deleteCookie = (name: string, path: string): void => {
  setCookie(name, '', -1, path);
};

import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const useToggleModals = (initialModals: {
  [key: string]: boolean;
}): {
  modals: { [key: string]: boolean };
  setModals: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  toggleModals: (modal: string, show?: boolean) => void;
} => {
  const [modals, setModals] = useState(initialModals);

  const toggleModals = useCallback(
    (modal: string, show?: boolean) => {
      setModals((showModals) => ({ ...showModals, [modal]: show === undefined ? !showModals[modal] : show }));
    },
    [setModals],
  );

  return { modals, setModals, toggleModals };
};

export default useToggleModals;

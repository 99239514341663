import React from "react";
import './FullScreenLoader.scss';

function FullScreenLoader() {
  return (
    <div className="loader-holder">
      <div className="logo-holder">
        <div className="logo"></div>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default FullScreenLoader;

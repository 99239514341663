const routes = [
  {
    href: '/',
    path: ['/'],
    label: 'Home',
    feature: 'redirect',
  },
  {
    href: '/token',
    path: ['/token', '/token/upload'],
    label: 'Token',
    feature: 'token',
  },
  {
    href: '/admin',
    path: ['/admin', '/admin/create'],
    label: 'Admin',
    feature: 'admin',
  },
  {
    href: '/admin',
    path: ['/admin', '/admin/remote-servers'],
    label: 'Remote Servers',
    feature: 'remote_server',
  }
]

export default routes;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../../app/store';
import { ResumableUploadsType, TenantConfigurations } from '../../api/upload.interface';

interface TokenInformation {
  token_id?: string;
  controller?: string;
  architecture?: string;
  other?: string;
}

interface TokenUpload {
  name?: string;
  version?: string;
  component?: string;
}

interface TokenSuccess {
  email_addresses?: string[];
}

interface User {
  configurations?: TenantConfigurations['configurations'];
}

const initialState: { information: TokenInformation, upload: TokenUpload, success: TokenSuccess, user: User } = {
  information: {},
  upload: {},
  success: {},
  user: {},
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setTokenInformation: (state, action: PayloadAction<TokenInformation>) => {
      state.information = { token_id: state.information.token_id, ...action.payload };
    },
    setTokenUpload: (state, action: PayloadAction<TokenUpload>) => {
      state.upload = action.payload;
    },
    setTokenSuccess: (state, action: PayloadAction<TokenSuccess>) => {
      state.success = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    }
  }
});

export const { setTokenInformation, setTokenUpload, setTokenSuccess } = tokenSlice.actions;
export const resumableUploadsConfigSelector = (state: RootState): ResumableUploadsType | undefined => state.token.user.configurations?.resumable_uploads;

export default tokenSlice.reducer;

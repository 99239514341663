import React from "react";
import { Link } from "react-router-dom";
import './Logo.scss'

function Logo() {
  return (
    <div className="logo-holder">
      <Link to="/">
        <div className="cybellum-sign" title="Cybellum" />
        <div className="cybellum-name" title="Cybellum" />
      </Link>
    </div>
  );
}

export default Logo;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { adminApi } from "../api/admin";
import { uploadApi } from "../api/upload";
import { remoteSeversApi } from "../api/remote_servers";
import tokenSlice from "../features/token/tokenSlice";
import appSlice from "./appSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    token: tokenSlice,
    [adminApi.reducerPath]: adminApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [remoteSeversApi.reducerPath]: remoteSeversApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(adminApi.middleware, uploadApi.middleware, remoteSeversApi.middleware)
});

export const getToken = (): string => {
  const state = store.getState();
  return state.token.information.token_id as string;
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

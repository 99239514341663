import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import { RemoteEnvironment, DetailsModalWindowProps } from "../../../api/remote_servers.interface";
import { getCookie } from "../../../utils/cookies";
import { useEditRemoteEnvironmentDetailsMutation } from "../../../api/remote_servers";
import { useTranslation } from "react-i18next";

const onEditDetails = (
  anchorEnvironment: RemoteEnvironment,
  setAnchorEnvironment: { (environment: RemoteEnvironment): void; (environment: RemoteEnvironment): void },
  propertyName: string,
  new_value: string,
) => {
  switch (propertyName) {
    case 'user':
      setAnchorEnvironment({ ...anchorEnvironment, user: new_value });
      break;
    case 'password':
      setAnchorEnvironment({ ...anchorEnvironment, password: new_value });
      break;
    default:
      break;
  }
};

const DetailsDialog: React.FC<DetailsModalWindowProps> = ({ stateShowDetails, onCloseShowDetails, anchorRemoteEnvironment, setAnchorEnvironment }) => {
  const { t } = useTranslation();
  const accessKey = getCookie('access_key');
  const [editRemoteEnvironmentDetails, { isLoading }] = useEditRemoteEnvironmentDetailsMutation();
  const [originalAnchorEnvironment, setOriginalAnchorEnvironment] = React.useState<RemoteEnvironment>(anchorRemoteEnvironment);
  const onSaveDetails = async (anchorEnvironment: RemoteEnvironment, onCloseShowDetails: () => void, setOriginalAnchorEnvironment: (anchorEnvironment: RemoteEnvironment) => void) => {
    editRemoteEnvironmentDetails({access_key: accessKey, environment: anchorEnvironment})
      .unwrap()
      .then(() => {
        toast.success(`${t('remote-servers.description-details')} ${anchorEnvironment.remote_ip}`);
        setOriginalAnchorEnvironment(anchorEnvironment);
      })
      .catch((error) => {
        resetChanges();
        toast.error(error?.data?.error_message || 'Error');
      })
      .finally(() => {
        onCloseShowDetails();
      })
  };

  // Reset the changes made to anchorRemoteEnvironment
  const resetChanges = () => {
    setAnchorEnvironment(originalAnchorEnvironment);
  };

  const handleSaveDetails = () => {
    onSaveDetails(anchorRemoteEnvironment, onCloseShowDetails, setOriginalAnchorEnvironment).then();
  };

  interface AnchorEnvironment {
    user: string;
    password: string;
  }

  interface Field {
    label: string;
    key: keyof AnchorEnvironment;
    type: string;
    options?: { value: string; label: string }[];
  }

  const fields: Field[] = [
    { label: t('remote-servers.user'), key: 'user', type: 'text' },
    { label: t('remote-servers.password'), key: 'password', type: 'text' },
  ];

  return (
    <Dialog open={stateShowDetails} onClose={() => { onCloseShowDetails(); resetChanges(); }}>
      <Tooltip title={anchorRemoteEnvironment.remote_ip}>
        <DialogTitle sx={{ textAlign: "center" }}>
          {t('remote-servers.title-details-for')}{' '}
          {anchorRemoteEnvironment.remote_ip.length > 20 ? `${anchorRemoteEnvironment.remote_ip.slice(0, 20)}...` : anchorRemoteEnvironment.remote_ip}
        </DialogTitle>
      </Tooltip>
      <DialogContent sx={{ width: 400, paddingBottom: 2 }}>
        <Grid container justifyContent="center" spacing={2}>
          {fields.map((field) => (
            <Grid item xs={12} key={field.key}>
              <TextField
                onChange={(event) => onEditDetails(anchorRemoteEnvironment, setAnchorEnvironment, field.key, event.target.value)}
                label={field.label}
                size="small"
                value={anchorRemoteEnvironment[field.key]}
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="space-around" sx={{ marginTop: 4 }}>
          <Grid item xs={5}>
            <Button fullWidth onClick={() => handleSaveDetails()} variant="contained" disabled={isLoading}>
              {t('remote-servers.save')}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button fullWidth onClick={() => { onCloseShowDetails(); resetChanges(); }} variant="contained" disabled={isLoading}>
              {t('remote-servers.close')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export interface DetailsButtonProps {
  remoteEnvironment: RemoteEnvironment;
}

const DetailsButton: React.FC<DetailsButtonProps> = ({ remoteEnvironment }) => {
  const { t } = useTranslation();
  const [stateDetailsDialog, setShowDetails] = React.useState(false);
  const openDetailsDialog = () => setShowDetails(true);
  const closeDetailsDialog = () => setShowDetails(false);

  const [anchorEnvironment, setAnchorEnvironment] = React.useState<RemoteEnvironment>(remoteEnvironment);

  return (
    <div>
      <Tooltip title={t('remote-servers.title-details') as string}>
        <IconButton onClick={openDetailsDialog}>
          <RemoveRedEyeIcon />
        </IconButton>
      </Tooltip>
      <DetailsDialog
        anchorRemoteEnvironment={anchorEnvironment}
        setAnchorEnvironment={setAnchorEnvironment}
        stateShowDetails={stateDetailsDialog}
        onCloseShowDetails={closeDetailsDialog}
      />
    </div>
  );
};

export default DetailsButton;

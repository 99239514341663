import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from '../../../app/store';

const useVerifyTokenExists = () => {
  const { token_id } = useSelector((state: RootState) => state.token.information)
  const history = useHistory();

  useEffect(() => {
    if (!token_id) {
      history.replace(`/token`);
    }
  }, [history, token_id])
};

export default useVerifyTokenExists;

import { ErrorMessage } from "@hookform/error-message";
import { FormControl, FormHelperText, InputAdornment, InputBase, InputLabel, TextField } from "@mui/material";
import React, { ForwardedRef, forwardRef, ReactElement, useMemo } from "react";
import { isEmpty } from "lodash";
import "./Input.scoped.scss";
import { InputProps } from "./interface";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/error.svg";

const Input = forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
  const { label, id, errors = {}, type = "text", login = false, required = false, autocomplete, ...rest } = props;
  const error = errors[id] && !isEmpty(errors[id]);

  const VariableInputBase = useMemo(() => {
    if (autocomplete) {
      return TextField;
    }
    return InputBase;
  }, [login, autocomplete]); // eslint-disable-line

  return (
    <>
      <FormControl className="full-width" error={error}>
        {label && (
          <InputLabel shrink htmlFor={id} error={error}>
            {label} {required && <span className="required">*</span>}
          </InputLabel>
        )}

        <VariableInputBase
          error={error}
          inputRef={ref}
          {...rest}
          name={id}
          id={id}
          type={type}
          className="input-base full-width"
          aria-describedby={`${id}-helper-text`}
          endAdornment={
            error && (
              <InputAdornment position="end">
                <ErrorIcon />
              </InputAdornment>
            )
          }
        />
        {error && (
          <FormHelperText error={error} id={`${id}-helper-text`}>
            <ErrorMessage
              errors={errors}
              name={id}
              render={({ messages }) =>
                messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <React.Fragment key={type}>
                        {message} <br />
                      </React.Fragment>
                    ))
                  : null
              }
            />
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
});

export default Input;

import { isObject } from "lodash";
import { DateTime } from "luxon";

export const randomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const formatBytes = (bytes: number, decimals = 2, binary = true): { size: number; sizeUnits: string; sizeLabel: string; binary?: boolean } => {
  if (bytes === 0) {
    return { size: 0, sizeUnits: 'Bytes', sizeLabel: '0 Bytes' };
  }
  const k = binary ? 1024 : 1000; // https://www.gbmb.org/kilobytes;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const level = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / k ** level).toFixed(dm));
  return { size, sizeUnits: sizes[level], sizeLabel: `${size} ${sizes[level]}` };
};

export const formatSeconds = (seconds: number): { remain: number; remainUnits: string } => {
  const currentTime = DateTime.local();
  const now = DateTime.local().plus({ seconds });

  if (seconds <= 60) {
    return { remain: Math.floor(now.diff(currentTime, 'seconds').seconds), remainUnits: 'seconds' };
  }
  if (seconds <= 3600) {
    return { remain: Math.floor(now.diff(currentTime, 'minutes').minutes), remainUnits: 'minutes' };
  }
  if (seconds <= 86400) {
    return { remain: Math.floor(now.diff(currentTime, 'hours').hours), remainUnits: 'hours' };
  }
  if (seconds <= 31622400) {
    return { remain: Math.floor(now.diff(currentTime, 'days').days), remainUnits: 'days' };
  }
  return { remain: Math.floor(now.diff(currentTime, 'years').years), remainUnits: 'years' };
};


export function setToFormDataUnserialized(data: any, formDataObj = new FormData(), prevKey = ''): FormData {
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      setToFormData(data[key], prevKey + key, formDataObj, true);
    } else if (isObject(data[key])) {
      setToFormDataUnserialized(data[key], formDataObj, `${prevKey}${key}.`);
    } else {
      formDataObj.set(prevKey + key, data[key]);
    }
  });
  return formDataObj;
}

export const setToFormData = (data: string[], key: string, formDataObj: FormData, withIndex = false): FormData => {
  if (data.length > 0) {
    data.forEach((element: string, index: number) => {
      if (isObject(element)) {
        setToFormDataUnserialized(element, formDataObj, `${key}${withIndex ? `[${index}].` : '.'}`);
      } else {
        formDataObj.append(`${key}${withIndex ? `[${index}]` : ''}`, element);
      }
    });
  }
  return formDataObj;
};

export function getDate(date?: string, dateFormat?: Intl.DateTimeFormatOptions): string {
  return date ? `${DateTime.fromISO(date).toLocaleString(dateFormat || DateTime.DATE_FULL)}` : '';
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from './store';
import { nanoid } from 'nanoid';

const initialState: { securityPolicy: boolean, sessionId: string } = {
  securityPolicy: false,
  sessionId: nanoid(),
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    securityPolicy: (state, action: PayloadAction<boolean>) => {
      state.securityPolicy = action.payload;
    },
  }
});

export const getAppSessionId = (state: RootState): string => state.app.sessionId;
export const { securityPolicy } = appSlice.actions;

export default appSlice.reducer;

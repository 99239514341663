import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import SelectHOC from './Select';
import { SelectProps } from './interface';

function SelectForm(props: SelectProps): ReactElement {
  const { label, id, rules = {}, control, errors = {}, defaultValue, children, required = false, disabled, ...rest } = props;
  const error = errors[id] && !isEmpty(errors[id]);

  return (
    <FormControl className="full-width" error={error}>
      <InputLabel shrink htmlFor={id} error={error}>
        {label} {required && <span className="required">*</span>}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <SelectHOC {...field} disabled={disabled}>
            {children}
          </SelectHOC>
        )}
        name={id}
        rules={rules}
        control={control}
        {...(defaultValue ? { defaultValue } : {})}
        {...rest}
      />
      {error && (
        <FormHelperText error={error} id={`${id}-helper-text`}>
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ messages }) =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <React.Fragment key={type}>
                  {message} <br />
                </React.Fragment>
              ))
            }
          />
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectForm;

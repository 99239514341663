import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes/RoutesList";
import "typeface-montserrat";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import { toastConfig } from "./config";
import "./app/i18n";
import FullScreenLoader from "./components/fullScreenLoader/FullScreenLoader";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<FullScreenLoader />}>
      <Provider store={store}>
        <Routes />
        <ToastContainer {...toastConfig} />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
